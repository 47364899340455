<template>
  <!-- eslint-disable max-len -->
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!imgLoad"
      :overflow="true"
    ></Loading>
  </transition>

  <!-- Main content -->
  <TopNav></TopNav>
  <div class="container-fluid mt-nav">
    <h1 class="h1-xs-small text-uppercase">
      acknowledgement
    </h1>
    <div class="row">
      <div class="col-12">
        <div class="h3 text-uppercase mt-3 mt-lg-4">
          organisers
        </div>
        <div
          class="mt-3"
        >
          <template
            v-for="(logo, index ) in organizerLogoPath"
            :key="logo"
          >
            <img
              :src="`${this.publicPath}logos/${logo}`"
              alt=""
              class="acknowledgement-logo-wrapper me-5 mb-3"
              @load="onImgLoad(10 + index)"
            >
          </template>
        </div>
      </div>
      <!-- presenter -->
      <div class="col-12">
        <div class="h3 text-uppercase mt-3 mt-lg-5">
          presenters
        </div>
        <div
          class="mt-3"
        >
          <template
            v-for="(logo, index) in presenterLogoPath"
            :key="logo"
          >
            <img
              :src="`${this.publicPath}logos/${logo}`"
              alt=""
              class="acknowledgement-logo-wrapper me-5 mb-4"
              @load="onImgLoad(20 + index)"
            >
          </template>
        </div>
      </div>
      <!-- presenter -->
      <!-- Advisory Board -->
      <div class="col-12">
        <div class="h3 text-uppercase mt-3 mt-lg-5">
          Advisory Board
        </div>
        <div
          class="mt-3"
        >
          <p class="en">
            Louis Nixon, Director and Professor of Academy of Visual Arts, Hong Kong Baptist University
          </p>
          <p class="en">
            Yu Ding, Head of Art and Humanities, CAFA, Beijing
          </p>
          <p class="en">
            Kirke Kangro, Dean of Fine Arts, Estonian Academy of Arts
          </p>
          <p class="en">
            Anastasia Meadors, Director, Art and Design Department, University of California, Berkeley Extension, San Francisco
          </p>
          <p class="en">
            Cecilia Casorati, Director of Fine Arts Academy of Rome, Italy
          </p>
          <p class="en mb-0">
            Lampo Leong, Director, Centre for Arts & Design, University of Macau
          </p>
          <div
            class="
              small en
            "
          >
            Chairman of 1a space
          </div>
          <div
            class="
              small en
            "
          >
            Chairman of Videotage
          </div>
        </div>
      </div>
      <!-- Advisory Board -->
      <!-- Supporting organization -->
      <div class="col-12">
        <div class="h3 text-uppercase mt-3 mt-lg-5">
          Supporting organisations / Individuals
        </div>
        <div
          class="mt-3"
        >
          <template
            v-for="(logo, index) in supportLogoPath"
            :key="logo"
          >
            <img
              :src="`${this.publicPath}logos/${logo}`"
              alt=""
              class="acknowledgement-logo-wrapper me-5 mb-4"
              @load="onImgLoad(30 + index)"
            >
          </template>
        </div>
      </div>
      <!-- Supporting organization -->
      <!-- Supports -->
      <div class="col-12 col-lg-7">
        <div class="h3 text-uppercase mt-3 mt-lg-5">
          SUPPORTS
        </div>
        <div
          class="mt-3"
        >
          <p class="en">
            Dr Wei Shen AIK, Department of Chemical Biology, HKBU | Pirmin ALTENBURGER | Tony CHEUNG | <br>Alan GORDIE | Homan HO, FabLab Tokwawan | Dr Anton PETROV, NASA Centre for the Origins of Life |<br>Dr. Susan SLAVNEY, McDonnell Center for the Space Sciences |<br>The Getz Corporation (Hong Kong) Limited
          </p>
        </div>
      </div>
      <!-- Supports -->
      <!-- Spatial Consultants -->
      <div class="col-12 col-lg-7">
        <div class="h3 text-uppercase mt-2">
          SPATIAL CONSULTANTS
        </div>
        <div
          class="mt-2"
        >
          <p class="en">
            Gene Miao (1:1 Limited), Rain CHAN (Architect)
          </p>
        </div>
      </div>
      <!-- Spatial Consultants -->
      <!-- Curatorial Assistants -->
      <div class="col-12 col-lg-7">
        <div class="h3 text-uppercase mt-2">
          CURATORIAL ASSISTANTS
        </div>
        <div
          class="mt-2"
        >
          <p class="en">
            CHEUNG Sin Ki Sindy, LEE Wan Tak Dionne, Kathy LEUNG, LUK Kam Man Katie, MAN Cheuk Nok Charlotte, TONG Chi Kit Jacky, WANG Caiyang Kara, WONG Ching In Janet, YEUNG Yin Ting Tina
          </p>
        </div>
      </div>
      <!-- Curatorial Assistants -->
      <!-- Designers & Developer -->
      <div class="col-12 col-lg-7">
        <div class="h3 text-uppercase mt-2">
          KEY DESIGNER
        </div>
        <div
          class="mt-2"
        >
          <p class="en">
            Kris FUNG
          </p>
        </div>
        <div class="h3 text-uppercase mt-2">
          WEB DESIGNER | WEB DEVELOPER
        </div>
        <div
          class="mt-2"
        >
          <p class="en">
            Echo HUI
          </p>
        </div>
      </div>
      <!-- Designers & Developer -->
      <!-- Installation Team -->
      <div class="col-12 col-lg-7">
        <div class="h3 text-uppercase mt-2">
          INSTALLATION TEAM
        </div>
        <div
          class="mt-2"
        >
          <p class="en">
            Studio Mary
          </p>
        </div>
      </div>
      <!-- Installation Team -->
      <!-- Designer -->
      <div class="col-12 col-lg-7">
        <div class="h3 text-uppercase mt-2">
          DESIGNER
        </div>
        <div
          class="mt-2"
        >
          <p class="en">
            Jimmy LEE
          </p>
        </div>
      </div>
      <!-- Designer -->
      <!-- Project Assistant -->
      <div class="col-12 col-lg-7 mb-7">
        <div class="h3 text-uppercase mt-2">
          PROJECT ASSISTANTS
        </div>
        <div
          class="mt-2"
        >
          <p class="en">
            CHU Wing Lam Kelly, HO Tsz Long, HUI Ching Yiu, LAU Sin Ting, LI Man Pui
          </p>
        </div>
      </div>
      <!-- Project Assistant -->
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import TopNav from '@/components/front/TopNav.vue';
import Loading from '@/components/front/LoadingGeneral.vue';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      artworkImgLoadLog: null,
      imgLoad: false,
      organizerLogoPath: [
        'logo_hkbu.png',
        'logo_ava.png',
      ],
      presenterLogoPath: [
        'logo_rome.png',
        'logo_CAFA.png',
        'logo_sf.png',
        'logo_EKA.png',
        'logo_macau_uni.png',
        'logo_macau_CAD.png',
        'logo_1aSpace.png',
        'logo_videotage.png',
      ],
      supportLogoPath: [
        'logo_Prof_Raymond_Fung.png',
        'logo_diptyque_paris.png',
        'logo_countryside.png',
        'logo_Augmented_Creative_Lab.png',
      ],
    };
  },
  components: {
    Loading,
    TopNav,
  },
  methods: {
    /**
     * Page Load effects
     */
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 1000);
    },
    /**
     * Main methods
     */
    onImgLoad(index) {
      const len = this.organizerLogoPath.length + this.presenterLogoPath.length + this.supportLogoPath.length;
      this.artworkImgLoadLog.add(index);
      if (this.artworkImgLoadLog.size === len) {
        console.log('all logo loaded');
        this.imgLoad = true;
      }
    },
  },
  mounted() {
    console.clear();
    this.artworkImgLoadLog = new Set();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-primary');
  },
  unmounted() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-primary');
  },
};
</script>
